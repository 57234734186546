import React, { useEffect, useState } from "react";
import mqtt from "mqtt";

function App() {
  const [currentMedia, setCurrentMedia] = useState(null);

  useEffect(() => {
    // Configuration du client MQTT
    const options = {
      username: "dev",
      password: "lUnAagaPHJgKi18eQ5535F1zIsGPrHCb",
    };

    // Connexion au broker via WebSocket
    const client = mqtt.connect("wss://7u64nd.stackhero-network.com:443", options);

    client.on("connect", () => {
      console.log("Connecté avec succès au broker MQTT !");
      client.subscribe("boutique1", (err) => {
        if (err) {
          console.error("Erreur lors de l'abonnement au topic :", err);
        } else {
          console.log("Abonné au topic boutique1");
        }
      });
    });

    client.on("message", (topic, message) => {
      console.log(`Message reçu sur ${topic}: ${message.toString()}`);
      setCurrentMedia(message.toString());
    });

    client.on("error", (err) => {
      console.error("Erreur de connexion MQTT :", err);
    });

    // Nettoyage lors de la déconnexion
    return () => {
      client.end(true, () => {
        console.log("Déconnecté du broker MQTT");
      });
    };
  }, []);

  const renderMedia = () => {
    if (!currentMedia) return null;

    const mediaType = (() => {
      if (currentMedia.match(/\.(jpg|jpeg|png|gif|webp)$/i)) return "image";
      if (currentMedia.match(/\.(mp4|webm|ogg)$/i)) return "video";
      if (currentMedia.match(/\.(mp3|wav|aac|flac)$/i)) return "audio";
      if (currentMedia.match(/vimeo\.com/)) return "vimeo";
      if (currentMedia.match(/youtube\.com|youtu\.be|youtube\.com\/shorts/)) return "youtube";
      return "unsupported";
    })();

    switch (mediaType) {
      case "image":
        return (
          <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
            <div
              style={{
                backgroundImage: `url(${currentMedia})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                filter: "blur(20px)",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                zIndex: -1,
                background: "radial-gradient(circle, rgba(0,0,0,0) 70%, rgba(0,0,0,1) 100%)",
              }}
            ></div>
            <img
              src={currentMedia}
              alt="Received Media"
              style={{ width: "100vw", height: "100vh", objectFit: "contain" }}
            />
          </div>
        );
      case "video":
        document.body.style.backgroundColor = "black";
        document.body.style.overflow = "hidden"; // Disable scrolling
        return (
          <div
            style={{
              backgroundColor: "black",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              overflow: "hidden",
            }}
          >
            <video
              src={currentMedia}
              autoPlay
              loop
              controls
              style={{ width: "100vw", height: "100vh", objectFit: "contain" }}
            />
          </div>
        );
      case "audio":
        document.body.style.backgroundColor = "black";
        document.body.style.overflow = "hidden"; // Reset background for non-video
        return (
          <audio
            src={currentMedia}
            controls
            autoPlay
            style={{ width: "100vw", height: "100vh" }}
          />
        );
      case "vimeo":
        document.body.style.backgroundColor = "black";
        document.body.style.overflow = "hidden"; // Reset background for non-video
        const vimeoId = currentMedia.match(/vimeo\.com\/([\d]+)/)?.[1];
        if (vimeoId) {
          return (
            <iframe
              src={`https://player.vimeo.com/video/${vimeoId}?autoplay=1&loop=1`}
              title="Vimeo Stream"
              style={{ width: "100vw", height: "100vh", border: "none" }}
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          );
        }
        return <p>Vimeo URL invalide : {currentMedia}</p>;
      case "youtube":
        document.body.style.backgroundColor = "black";
        document.body.style.overflow = "hidden"; // Reset background for non-video
        const youtubeId = currentMedia.match(/(?:youtube\.com.*[?&]v=|youtu\.be\/|youtube\.com\/shorts\/)([a-zA-Z0-9_-]+)/)?.[1];
        if (youtubeId) {
          return (
            <iframe
              src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&loop=1&playlist=${youtubeId}`}
              title="YouTube Stream"
              style={{ width: "100vw", height: "100vh", border: "none" }}
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          );
        }
        return <p>YouTube URL invalide : {currentMedia}</p>;
      default:
        document.body.style.backgroundColor = "";
        document.body.style.overflow = ""; // Reset background for non-video
        return <p>Format non supporté : {currentMedia}</p>;
    }
  };

  return (
    <div style={{ textAlign: "center", position: "relative" }}>
      {currentMedia ? (
        renderMedia()
      ) : (

        //mettre le background de la page en noir
        document.body.style.backgroundColor = "black"

      )}
    </div>
  );
}

export default App;
